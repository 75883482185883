.HeaderContainer{
    width: 100%;
    padding: 10px 40px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    background: rgba(255, 255, 255, 0);
    position: relative;
}
.HeaderLogo img {
    max-width: 150px;
    width: 100%;
    height: auto;
}
.HeaderContainer h5 {
    color: white;
    font-size: 1.5rem;
}

@media  screen and (max-width: 600px) {
    .HeaderContainer{
        width: 100%;
        padding: 20px 10px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        background: rgba(255, 255, 255, 0);
        position: relative;
    }
    .HeaderContainer h5 {
        color: white;
        font-size: 1rem;
    }
    
}