.menuLanding {
    padding: 0.5rem 0;
    background-color: #69a33b;
    border-radius: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 80%;
}

.menuLanding button {
    color: white !important;
    border-radius: 50px !important;
    padding: 0.4rem 1rem;
    background: #69a33b !important;
    border: none;
    font-size: 1rem;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.menuLanding button:hover {
    color: black !important;
    background-color: white !important;
    border-radius: 50px !important;
    padding: 0.4rem 1rem;
    transition: all ease 1s;
}

.containerNav {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #efefef;
    padding: 1rem 0;
}

.bgGrey {
    background-color: #efefef;
}

.gridPaquetes {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
}

/* *Clase cabecera */
.logoLanding {
    width: 100%;
    height: auto;
    max-width: 100px;
    margin: 1rem 0;
}

.gridCabecera {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    color: white;
}

.diasLogo {
    width: 100%;
    height: auto;
    max-width: 25px;
}

.titulo {
    color: white;
    font-size: larger;
}

.cabecera {
    padding: 20px;
    background: rgb(2, 0, 36);
    background: linear-gradient(180deg, rgb(28, 26, 67) 0%, rgb(44, 44, 124) 35%, rgb(110, 208, 228) 100%);
}

/* *Contenedos de imagnes */
.containerImages {
    position: relative;
}

.personasLogo {
    width: 100%;
    height: auto;
    max-width: 300px;
}

.mundo {
    width: 100%;
    height: auto;
    max-width: 300px;
    ;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    z-index: -1;
}

/* *Tarjeta formulario */
.cardContentForm {
    max-width: 300px;
    width: 300px;
    background: white;
    margin: auto;
    border-radius: 15px;
    padding: 5px 10px;
    margin-bottom: 30px;
}

/* *taps */
.tabsContainer {
    font-family: Arial, sans-serif;
}

.tabs {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}

.tabs li {
    position: relative;
    flex: 1;
    text-align: center;

}

.tabs li button {
    background-color: #8a8a8a;
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    padding: 0;
}

.tabs li button:hover {
    background-color: #0092D2;
}

.activeTap {
    background-color: #0092D2 !important;
}

.tabs li::after {
    content: '';
    position: absolute;
    top: 15px;
    /* Ajusta la posición verticalmente */
    left: calc(100% - 25px);
    /* Ajusta la posición horizontalmente */
    height: 2px;
    width: calc(100% - 40px);
    /* Ajusta la longitud de la línea */
    background-color: #1594cb;
}

.tabs li:last-child::after {
    display: none;
    /* Oculta la línea después del último elemento */
}

.tabs span {
    position: absolute;
    margin: auto;
    color: black;
    bottom: -18px;
    left: 0;
    right: 0;
    font-size: 10px;
    font-weight: 400;

}

.tab {
    padding: 10px 20px;
    margin-right: 10px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-bottom: none;
    background-color: #f0f0f0;
    border-radius: 5px 5px 0 0;
}

.tab:hover {
    background-color: #e0e0e0;
}

.activeTab {
    background-color: #fff;
    border-bottom: 1px solid #ccc;
}

.tabContent {
    padding: 20px 0;
    height: 400px;
}


/* *Boton de wahtsapp */
.whatsappButton {
    margin: 10px 0;
    display: inline-block;
    padding: 10px 40px;
    background-color: #69a33b;
    /* Color verde de WhatsApp */
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    border: none;
    border-radius: 40px;
    /* Redondear los bordes */
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* Sombra ligera */
    transition: background-color 0.3s ease;
    /* Transición suave de color de fondo */
}

.whatsappButton img {
    width: 100%;
    height: auto;
    max-width: 35px;
    padding: 2px;
}

.whatsappButton:hover {
    color: white;
}


/* *Footer */
.FooterIconsContent {
    margin-top: 40px;
    z-index: 4;
}

.FooterIcons {
    width: 100%;
    height: auto;
    max-width: 30px;
    fill: white;
    z-index: 4;
}

.direcion {
    color: white;
    margin: 40px 0;
    z-index: 4;
}

.logoContentenFooter {
    margin: 30px auto;
    width: 100%;
    max-width: 450px;
    z-index: 4;
}

.avionIconoContentFooter {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: -1;
}