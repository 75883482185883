.cardPaqueteDetalle {
    background: #DDDDDD;
    padding: 20px;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    position: sticky;
    top: 10px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    color: #666666;
    

}
.flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    gap: 5px;
}
.flexCenter.col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}
.cardPaqueteItem.dias {
    grid-area: 1 / 1 / 2 / 2;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    border-bottom: 1px solid #727272;
}
.cardPaqueteItem.dias img{
    width: 100%;
    height: auto;
    max-width: 30px !important;
}
.cardPaqueteItem.vuelos {
    grid-area: 2 / 1 / 3 / 2;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    border-bottom: 1px solid #727272;
}
.cardPaqueteItem.vuelos img{
    width: 100%;
    height: auto;
    max-width: 160px !important;
}
.cardPaqueteItem.salidas {
    grid-area: 3 / 1 / 4 / 2;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    border-bottom: 1px solid #727272;
}
.cardPaqueteItem.salidas h2 {
    color: #19577A;
    font-weight: 900;
}
.cardPaqueteItem.montos {
    grid-area:  4 / 1 / 5 / 2;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    border-bottom: 1px solid #727272;
    padding-top: 5px;
}
.cardPaqueteItem.montos  h2{
    color: #6B9C39;
    font-weight: 900;
}
.cardPaqueteItem.precio {
    grid-area: 5 / 1 / 6 / 2;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    padding-top: 5px;
}
.cardPaqueteItem.precio h2{
    color: #6B9C39;
    font-weight: 900;
}

@media screen and (max-width: 600px) { 
    .contenedorFlexCenter .flexCenter {
        flex-direction: column !important;
    }
    .cardPaqueteDetalle h2 {
        font-size: 0.9rem !important;
    }
    .cardPaqueteDetalle h6 {
        font-size: 0.7rem !important;
    }
    .cardPaqueteDetalle h4 {
        font-size: 0.8rem !important;
    }
    .cardPaqueteDetalle{
        border-bottom-left-radius: 35px;
        border-bottom-right-radius: 35px;
        border-top-left-radius: 35px;
        border-top-right-radius: 35px;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(1, 1fr);
    }
    .cardPaqueteItem.dias img{
        max-width: 20px !important;
    }
    .cardPaqueteItem.dias {
        grid-area: 1 / 1 / 2 / 2;
        border-right: 1px solid #727272;
        border-bottom: none;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-content: center;
    }
    .cardPaqueteItem.dias .flexCenter:first {
        flex-direction: column;
    }
    .cardPaqueteItem.vuelos {
        grid-area: 1 / 2 / 2 / 3;
        border-right: 1px solid #727272;
        border-bottom: none;
        flex-direction: column;
    }
    .cardPaqueteItem.salidas {
        grid-area: 1 / 3 / 2 / 4;
        border-right: 1px solid #727272;
        border-bottom: none;
        flex-direction: column;
    }
    .cardPaqueteItem.montos {
        grid-area: 1 / 4 / 2 / 5;
        border-right: 1px solid #727272;
        border-bottom: none;
        flex-direction: column;
    }
    .cardPaqueteItem.precio {
        grid-area: 1 / 5 / 2 / 6;
        flex-direction: column;
    }
}
@media screen and (max-width: 450px) { 
    .cardPaqueteDetalle h2 {
        font-size: 0.6rem !important;
    }
    .cardPaqueteDetalle h6 {
        font-size: 0.5rem !important;
    }
    .cardPaqueteDetalle h4 {
        font-size: 0.6rem !important;
    }
}