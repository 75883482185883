.container {
    margin: 0 auto;
    width: 100%;
    padding: 10px;
}
.container .title{
    text-transform: uppercase;
    margin: 2rem 0;
    color: white;
    font-family: "LEMONMILK" !important;
}
.container .subTitle{
    font-weight: 700;
    color: #009EE2;

}
.container .box {
    hyphens: auto;
    font-size: 0.7rem;
    background: #F9F6EC;
    width: 100%;
    margin: 1rem auto;
    padding: 1rem;
    border-radius: 20px;
}