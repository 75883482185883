.CardPaqueteDetalle {
    background: #DDDDDD;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    position: sticky;
    top: 10px;
}

.CardPaqueteDetalle-Dias {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.CardPaqueteDetalle-vuelos,
.CardPaqueteDetalle-Montos,
.CardPaqueteDetalle-Salidas {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #000;
    padding: 15px 0;
}

.CardPaqueteDetalle h4,
.CardPaqueteDetalle h6 {
    color: #666666;

}

.CardPaqueteDetalle .BlueColor {
    color: #19577A;
    font-weight: 900;
}

.CardPaqueteDetalle .Blue {
    color: #0094D6;
    font-weight: 900;
}

.CardPaqueteDetalle .Green {
    color: #6B9C39;
    font-weight: 900;
}

.CardPaqueteDetalle-vuelos img {
    max-width: 150px !important;
}
.CardPaqueteDetalle img {
    width: 100%;
    height: auto;
    max-width: 100px;
}

.CardPaqueteDetalle .icon {
    max-width: 30px;
    margin: 2px;
}

.CardPaqueteDetalle .flex-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.CardPaqueteDetalle .flex-row h2 {
    margin: 0;
}

.CardPaqueteDetalle .flex-column {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
}

@media screen and (max-width: 600px) {
    .CardPaqueteDetalle {
        background: #DDDDDD;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        padding: 5px;
        border-radius: 35px;
        position: relative;
        top: 0;
    }

    .CardPaqueteDetalle-Dias {
        padding: 10px 12px;
    }

    .CardPaqueteDetalle-vuelos,
    .CardPaqueteDetalle-Montos,
    .CardPaqueteDetalle-Salidas {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        border: none;
        border-left: 1px solid #000;
        padding: 10px 12px;
    }

    .CardPaqueteDetalle-Dias {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
    }

    .CardPaqueteDetalle .icon {
        max-width: 20px;
        margin: 2px;
    }

    .CardPaqueteDetalle h4,
    .CardPaqueteDetalle h6 {
        color: #666666;
        font-size: 0.7rem;
        margin: 0;
    }

    .CardPaqueteDetalle h2 {
        margin: 0;
        font-size: 1rem;
    }

    .CardPaqueteDetalle img {
        width: 100%;
        height: auto;
        max-width: 50px;
    }
    
    .CardPaqueteDetalle-vuelos img {
        max-width: 80px !important;
    }
}