/* Marco del teléfono */
.phoneFrame {
    position: relative;
    width: 300px;
    /* Ancho del teléfono */
    height: 600px;
    /* Alto del teléfono */
    border: 1px solid #000;
    border-radius: 40px;
    background-color: #222;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

/* Pantalla del teléfono */
.phoneScreen {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 15px);
    /* Ajuste según el borde */
    height: calc(100% - 15px);
    margin: 7px;
    background-color: #000;
    overflow: hidden;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.phoneScreen video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Notch del iPhone */
.notch {
    position: absolute;
    top: 12px;
    left: 50%;
    transform: translateX(-50%);
    width: 120px;
    /* Ancho del notch */
    height: 30px;
    /* Alto del notch */
    background-color: #000;
    border-radius: 10px / 10px;
    z-index: 2;
}

/* Detalles adicionales */
.phoneFrame::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 4px;
    background-color: #333;
    border-radius: 2px;
}

.phoneFrame::after {
    content: "";
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 6px;
    background-color: #333;
    border-radius: 3px;
}



/* Botón de Play/Pausa */
.playButton {
    position: absolute;
    width: 60px;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    font-size: 24px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
}

.playButton:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

@media (max-width: 768px) {
    .phoneFrame {
        width: 200px;
        height: 400px;
    }

    .notch {
        width: 80px;
        height: 20px;
    }
}