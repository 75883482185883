.container {
    margin: 1rem auto;
    width: 100%;
    padding: 1rem 2rem;
    text-align: left;
}

.container .textLeft {
    text-align: left;
}

.container .title {
    text-transform: uppercase;
    font-weight: 700;
    margin: 1rem 0;
    color: #69A33B;
    text-align: left;
}

.container .subTitle {
    font-weight: 700;
    color: #009EE2;
    font-size: 0.7rem;
    position: relative;
    z-index: 99;
}

.container .subTitle span {
    font-weight: 700;
    color: white;
    font-size: 1rem;
    position: relative;
    margin-right: 12px;
    z-index: 99;
    font-weight: 700;
}

.container .subTitle span::before {
    content: "";
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 50%;
    background: rgb(105,163,59);
    background: linear-gradient(238deg, rgba(105,163,59,1) 0%, rgba(0,158,226,1) 100%);
    position: absolute;
    left: -5px;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: -1;
}

.container .box {
    hyphens: auto;
    font-size: 0.7rem;
    background: #F9F6EC;
    width: 100%;
    margin: 1rem auto;
    padding: 0.8rem 1rem;
    border-radius: 20px;
}