/* *Incluye */
.incluye {
    text-align: left;
    font-size: 12px !important;
    font-weight: 400 !important;
    padding: 10px 5px;
    font-family: "Poppins" !important;
}

.incluye li {
    list-style-image: url("../../../assets/Check.svg") !important;
}

.incluye p  span{
    font-size: 12px !important;
    font-weight: 400 !important;
    font-family: "Poppins" !important;
}

/* *Progress continaer scroll */

.progressContainer {
    position: fixed;
    top: 0;
    right: 0;
    width: 6px;
    height: 100%;
    background: #84c8e5;
    z-index: 1000;
    border-radius: 10px;
    max-height: 300px;
}
.progressBar {
    width: 100%;
    background: #1594cb;
    height: 0;
    border-radius: 10px;
    max-height: 300px;
}

.scrollContent {
    height: 300px;
    overflow-y: scroll;
    scrollbar-width: none; 
    scrollbar-color: hidden;
    padding-right: 10px;
    -webkit-overflow-scrolling: touch;
}