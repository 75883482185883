.container {
    margin: auto;
    width: 100%;
    padding: 3rem 2rem;
    text-align: center;
    background: #CCE8F5;
}

.container .title {
    text-transform: uppercase;
    font-weight: 700;
    margin: 1rem 0;
    color: #69A33B;
    text-align: center;
    font-family: "LEMONMILK" !important;
}

.iamgen {
    width: 100%;
    height: auto;
    max-width: 129px;
    margin: 1rem 0;
}
.card  {
    hyphens: auto;
    font-size: 0.7rem;
    background: #F9F6EC;
    width: 100%;
    margin: 1rem auto;
    padding: 1rem 3rem;
    border-radius: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
}
