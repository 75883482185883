.whatsappButton {
    margin: 24px 0 0 10px;
    display: inline-block;
    padding: 4px 20px;
    background-color: #39A935;
    /* Color verde de WhatsApp */
    color: #fff;
    font-size: 10px;
    text-decoration: none;
    text-align: center;
    border: none;
    border-radius: 12px;
    /* Redondear los bordes */
    cursor: pointer;
    box-shadow: 0 0 2px #39A935;
    /* Sombra ligera */
    transition: background-color 0.3s ease;
    /* Transición suave de color de fondo */
    font-family: "Poppins";
}

.whatsappButton img {
    width: 100%;
    height: auto;
    max-width: 17px;
    margin: 2px 12px;
}

.whatsappButton:hover {
    color: white;
}