.main {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 10px;
    border-spacing: border-box;
}

.grid2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 10px;
}

.logoBank {
    width: 100%;
    max-width: 60px;
    height: auto;
    margin: 0 auto;
}

@media (max-width: 600px) {
    .grid2 {
        grid-template-columns: 1fr; /* Dos columnas para pantallas medianas */
    }
    .textLeft {
        text-align: center !important;
    }
    .tabla,
    .tabla h5 {
        font-size: 0.8rem !important;
    }
    .textCaption {
        font-size: 0.7rem !important;
    }
    .vigenciaCaption {
        font-size: 0.7rem !important;
    }
    .divider {
        padding: 15px 0 !important;
    }
    .divider .title {
        font-size: 1rem;
    }
    .viegencia h5 {
        font-size: 0.8rem !important;
    }
    .logoBank {
        max-width: 40px;
    }
}


.textLeft {
    text-align: left;
}

.bold {
    font-weight: 700;
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0;
}

.logo {
    margin: 30px 0;
    height: auto;
    width: 100%;
    max-width: 250px !important;
}

.tabla li {
    list-style-image: url("../../assets/Check.svg");
}

.tabla {
    width: 100%;
    padding: 10px;
    position: relative;
}

.tabla th {
    background-color: #fff;
    color: #00A327;
    padding: 10px;
    position: relative;
}

.tabla td {
    position: relative;
}

.tabla td::after,
.tabla th::after {
    content: "";
    right: 0;
    top: 0;
    position: absolute;
    width: 2px;
    height: 100%;
    background: #00A327;
}
.noBorder.tabla th::after,
.noBorder.tabla td::after {
    background: white !important;

}

.tabla th.noBorder::after,
.tabla td.noBorder::after {
    content: "";
    right: 0;
    top: 0;
    position: absolute;
    width: 2px;
    height: 100%;
    background: #fff;
}

.tabla th.alter {
    background-color: #fff;
    color: #009BDB;
    padding: 10px;
}


.tabla td {
    background-color: #fff;
    color: #000;
    border-color: #00A327;
    border-style: solid;
    border: 1px 0 1px 0;
    padding: 10px;
}

.divider {
    background-color: #00A327;
    padding: 18px 0.2rem;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    margin-top: 40px;
}

.divider .title {
    color: #fff;
}

.title {
    color: #00A327;
    font-size: 1.5rem;
    margin: 0;
    padding-left: 1rem;
}

.textVerde {
    color: #00A327;
}

.textCaption {
    background-color: #f2ff00;
    font-size: 0.7rem;
    text-align: justify;
    padding: 5px;
    margin: 10px 0;
}

.textFinal {
    font-size: 0.6rem;
    text-align: justify;
    padding: 5px;
    margin: 50px 0 10px 0;
}

.creacion {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 0;
    margin-bottom: 30px;
}

.creacion p {
    margin: 0;
}

.creacion .content:nth-child(1) {
    padding: 0 5px;
    border-right: #00A327 2px solid;
}

.creacion .content:nth-child(2) {
    padding: 0 5px;
    border-left: #00A327 2px solid;
}

.viegencia {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.viegencia .content:nth-child(1) {
    text-align: center;
    width: 50%;
    padding: 0 5px;
    border-right: #00A327 2px solid;
}

.viegencia .content:nth-child(2) {
    text-align: center;
    padding: 0 5px;
    width: 50%;
    border-left: #00A327 2px solid;
}

.vigenciaCaptionTitle {
    text-align: left;
    font-size: 1rem;
    font-weight: 600;
}

.vigenciaCaption {
    text-align: justify;
    font-size: 0.8rem;
}

.trCredito {
  border: 3px solid #00A327;
}
.trCredito:nth-child(1){
    border-right: 0 !important;
}
.trCredito:nth-child(2){
    border-left: 0 !important;
    border-right: 0 !important;
}
.trCredito:nth-child(3){
    border-left: 0 !important;
}